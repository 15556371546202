import { graphql } from "gatsby"
import React from "react"
import useArticlePath from "../../../hooks/useArticlePath"
import BackgroundVideo from "../../atoms/BackgroundVideo"
import HighlightBlock, { HighlightBlockLayout } from "../../molecules/tileable-blocks/HighlightBlock"
import DatoImage from "../DatoImage"
//import * as styles from "./DatoHighlightToArticle.module.scss"

const DatoHighlightToArticle = (props: Queries.DatoHighlightToArticleFragment) => {
  //console.log('Rendering DatoHighlightToArticle', props)
  const getArticlePath = useArticlePath()

  const article = props.article

  if (!article) return null

  const url = getArticlePath(article.originalId)

  // onko artikkeli saatavilla tällä sivustolla
  //if (!url) return

  const mediaType = props.media?.mimeType?.split("/")[0]
  let media

  switch (mediaType) {
    case "video":
      media = props.media?.video && (
        <BackgroundVideo
          src={props.media?.video.mp4Url ?? undefined}
          poster={props.media?.video.thumbnailUrl ?? undefined}
        />
      )
      break
    case "image":
      media = props.media?.image && <DatoImage image={props.media.image} alt={props.media.alt || ""} />
      break
    default:
      media = article.mainImage && <DatoImage {...article.mainImage} alt={article.mainImage.alt || ""} />
      break
  }

  const text = props.text || article.excerpt

  return (
    <HighlightBlock
      linkTo={url}
      layout={(props.layout ?? "Tile") as HighlightBlockLayout}
      title={props.title || article.title || undefined}
      titleAs="h3"
      categoryTitle={article.articleCategory?.name ?? undefined}
      media={media}
      content={!!text && <p>{text}</p>}
    />
  )
}

export default DatoHighlightToArticle

export const query = graphql`
  fragment DatoHighlightToArticle on DatoCmsHighlightToArticle {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    media {
      ...DatoMedia
    }
    title
    text
    layout

    article {
      originalId
      slug
      mainImage {
        ...DatoImageDefault
      }
      title
      excerpt
      articleCategory {
        name
        slug
      }
      articleTags {
        label
      }
    }
  }
`
